import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useGetIdentity} from 'react-admin'

import {MerchantUsers, QueryRoot} from '../types/graphqlSchema'

const useSessionMerchantUser: UseSessionMerchantUser = () => {
  const {identity: {id: userId} = {}, loading: isLoadingIdentity} = useGetIdentity()
  const {
    data: {merchant_users: [merchantUser]} = {merchant_users: []},
    loading: isLoadingMerchantUser,
    refetch,
  } = useQuery<QueryRoot['merchant_users']>(
    MERCHANT_USER_QUERY, {skip: !userId, variables: {userId}}
  )
  return {loading: isLoadingIdentity || isLoadingMerchantUser, merchantUser, refetch}
}

const MERCHANT_USER_QUERY = gql`
  query($userId: uuid!){
    merchant_users(where: {user: {id: {_eq: $userId}}}){
      emailAddress
      id
      isAdmin
      firstName
      language
      lastName
      merchant{
        id
        name
        organizationId
      }
      user{id}
      userColor
    }
  }
`

interface UseSessionMerchantUser {
  (): {
    loading: boolean,
    merchantUser: MerchantUsers|undefined,
    refetch: () => Promise<any>,
  }
}

export default useSessionMerchantUser
