import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory'
import _ from 'lodash'

/* Apollo in-memory cache, customized for the flinkit domain by allowing to configure
 * cache keys for types which don't have an `id` field such as with many-to-many
 * relationship types. By configuring custom id paths for such types, this cache allows
 * for an easy cache setup for such types without a "standard" `id` field.
 */
const apolloCache = () => new InMemoryCache({
  // TODO: When migrating to Apollo 3.0, use `typePolicies` instead
  dataIdFromObject: o => {
    const idPaths = CUSTOM_TYPE_TO_CACHE_ID_PATHS[o.__typename as string]
    if (idPaths) {
      const missingIdPath = _.find(idPaths, p => !_.has(o, p))
      if (missingIdPath) {
        console.warn(
          `Unable to generate cache key for object of type '${o.__typename}': ` +
          `Property path '${missingIdPath}' does not exist. ` +
          "Did you forget to select it in a query or mutation?",
          o
        )
        return defaultDataIdFromObject(o)
      }
      const key = idPaths.map(p => _.get(o, p)).join('|')
      return `${o.__typename}:${key}`
    }
    return defaultDataIdFromObject(o)
  },
})

const CUSTOM_TYPE_TO_CACHE_ID_PATHS = {
  'user_chats': ['chatId'],
}

export default apolloCache
