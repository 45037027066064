import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useEffect, useMemo} from 'react'
import {useTranslate} from 'react-admin'

import RichTranslationText from '../components/RichTranslationText'
import ThirdPartyConnectionDialog from '../components/ThirdPartyConnectionDialog'
import useQueryParameters from '../hooks/useQueryParameters'
import {actionErrorCode, actionErrorData} from '../utils/errors'
import {removeQueryParameters} from '../utils/queryParameters'

const ThreeSixtyChannelsConnectionPage = () => {
  // https://docs.360dialog.com/partner/integration/integrated-onboarding-setup#apikeypermissionflow
  const {channels} = useQueryParameters()
  const channelIdentifiers = useMemo(
    () => channels?.replace(/^\[(.+)\]$/, '$1').split(','), [channels]
  )
  const [connectThreeSixtyChannels, {error, loading}] = useMutation(
    THREE_SIXTY_CHANNELS_CONNECTION_MUTATION, {variables: {channelIdentifiers}}
  )
  const reasonCodeToChannelIdentifiers = useMemo(
    () => fromReasonCodeToChannelIdentifiers(
      error ?
        actionErrorData(error)?.['failed_channel_identifiers'] ?? [] :
        []
    ), [error]
  )
  useEffect(() => {
    if (!channelIdentifiers) return
    removeQueryParameters()
    connectThreeSixtyChannels()
  }, [channelIdentifiers, connectThreeSixtyChannels])
  return (
    <ThirdPartyConnectionDialog
      error={error}
      errorDetails={
        Object.keys(reasonCodeToChannelIdentifiers ?? {})
          .map(reasonCode => (
            <RichTranslationText
              key={reasonCode}
              rootElement="span"
              translationKey={
                'pages.whatsappChannelsConnection.errorCodes.' +
                `${reasonCode}`
              }
              translationOptions={{
                channelIdentifiers:
                  reasonCodeToChannelIdentifiers?.[reasonCode].join(
                    ', '
                  ),
              }}
            />
          ))
      }
      errorText={useTranslate()('pages.whatsappChannelsConnection.connectionError')}
      loading={loading}
      onRetry={error ? () => connectThreeSixtyChannels(
        {
          variables: {
            channelIdentifiers: actionErrorData(error)?.['failed_channel_identifiers']
              .map(i => i.identifier),
          },
        }
      ) : undefined}
      primaryErrorMessage={
        error && (
          actionErrorCode(error) !== 'THREE_SIXTY_CHANNEL_METADATA_ERROR' ?
            error.message :
            <RichTranslationText
              translationKey={
                'pages.whatsappChannelsConnection.errorCodes.' +
                'THREE_SIXTY_CHANNEL_METADATA_ERROR'
              }
              translationOptions={{
                channelIdentifiers:
                  actionErrorData(error)?.['failed_channel_identifiers']
                    .map(i => i.identifier)
                    .join(', '),
              }}
            />
        )
      }
      successText={useTranslate()('pages.whatsappChannelsConnection.connectionSuccess')}
    />
  )
}

const fromReasonCodeToChannelIdentifiers = (
  failedChannelIdentifiers: {identifier: string, reason_code: string}[]
) => {
  if (!failedChannelIdentifiers.length) return undefined
  return failedChannelIdentifiers.reduce<Record<string, string[]>>(
    (prev, current) => {
      const reason_code = current.reason_code
      if (reason_code in prev) {
        prev[reason_code].push(current.identifier)
        return prev
      }
      prev[reason_code] = [current.identifier]
      return prev
    },
    {}
  )
}

const THREE_SIXTY_CHANNELS_CONNECTION_MUTATION = gql`
  mutation($channelIdentifiers: [String!]!){
    connectThreeSixtyChannels(channelIdentifiers: $channelIdentifiers){
      whatsappAccount{id phoneNumber}
    }
  }
`

export default ThreeSixtyChannelsConnectionPage
