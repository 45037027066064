import {MutationResultWrapper, useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {MutationRoot, QueryRoot} from '../../types/graphqlSchema'

const useIsShowingTranslations = (chatId?: string) => {
  const {
    data: {user_chats: [{isShowingTranslations = false} = {}] = []} = {},
  } = useQuery<QueryRoot['user_chats']>(USER_CHAT_QUERY, {
    fetchPolicy: 'cache-and-network', skip: !chatId, variables: {chatId},
  })
  const [
    updateIsShowingTranslations,
  ] = useMutation<MutationRoot['insert_user_chats_one']>(
    UPSERT_USER_CHAT_MUTATION,
    {update: (cache, {data}) => {
      if (data?.insert_user_chats_one) {
        const {insert_user_chats_one: userChat} = data
        cache.writeQuery({
          data: {user_chats: [userChat]},
          query: USER_CHAT_QUERY,
          variables: {chatId},
        })
      }
    }},
  )
  const setIsShowingTranslations = useCallback(
    (isShowingTranslations: boolean) => {
      updateIsShowingTranslations({
        optimisticResponse: {
          insert_user_chats_one: {
            __typename: 'user_chats', chatId, isShowingTranslations,
          },
        // TODO: Double type cast needed since `__typename` field doesn't exist in
        //       our schema types. Should be added to avoid this
        } as unknown as MutationResultWrapper<MutationRoot['insert_user_chats_one']>,
        variables: {chatId, isShowingTranslations}},
      )
    },
    [chatId, updateIsShowingTranslations]
  )
  return [isShowingTranslations, setIsShowingTranslations] as const
}

const USER_CHAT_QUERY = gql`query($chatId: uuid!){
  user_chats(where: {chatId: {_eq: $chatId}}){
    chatId
    isShowingTranslations
  }
}`

const UPSERT_USER_CHAT_MUTATION = gql`mutation(
  $chatId: uuid!
  $isShowingTranslations: Boolean!
){
  insert_user_chats_one(
    object: {
      chatId: $chatId
      isShowingTranslations: $isShowingTranslations
    },
    on_conflict: {
      constraint: user_chats_pkey,
      update_columns: [isShowingTranslations]
    }
  ) {chatId isShowingTranslations}
}`

export default useIsShowingTranslations
