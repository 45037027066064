import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {FileCopyOutlined} from '@material-ui/icons'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
import {addDays, addMonths, format, isAfter} from 'date-fns'
import * as dateLocales from 'date-fns/locale'
import gql from 'graphql-tag'
import {FC, useCallback, useMemo, useState} from 'react'
import {useLocale, useNotify, useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedAlert from '../../components/ExtendedAlert'
import ExtendedDialog from '../../components/ExtendedDialog'
import ExtendedTooltip from '../../components/ExtendedTooltip'
import {ChevronDownIcon} from '../../components/icons'
import {ChatSummaries, MutationRoot, QueryRoot} from '../../types/graphqlSchema'

const ChatSummaryDialog = ({chatId, onClose, open}) => {
  const locale = useLocale()
  const styles = useStyles()
  const translate = useTranslate()
  const {
    data: {chat_summaries: summaries = []} = {},
    loading: isLoadingChatSummaries,
  } = useQuery<QueryRoot['chat_summaries']>(
    CHAT_SUMMARIES_QUERY,
    {skip: !(chatId && open), variables: {chatId}}
  )
  const summariesLength = useMemo(() => summaries.length, [summaries.length])
  const [
    summarizeChat,
    {loading: isSummarizingChat},
  ] = useMutation<MutationRoot['summarizeChat']>(
    SUMMARIZE_CHAT_MUTATION,
    {
      update: (cache, {data}) => {
        const summary = data?.summarizeChat.chatSummary
        if (summary) {
          const {chat_summaries: previousSummaries = []} = cache.readQuery<QueryRoot>({
            query: CHAT_SUMMARIES_QUERY,
            variables: {chatId},
          }) ?? {}
          const summaries = !!previousSummaries.length && isAfter(
            new Date(previousSummaries[0].fromDate), new Date(summary.toDate)
          ) ? [summary, ...previousSummaries] : [...previousSummaries, summary]
          cache.writeQuery({
            data: {chat_summaries: summaries},
            query: CHAT_SUMMARIES_QUERY,
            variables: {chatId},
          })
        }
      },
    }
  )
  const summarizeChatMessages = useCallback(
    ({fromDate, toDate}) => summarizeChat({variables: {chatId, fromDate, toDate}}),
    [chatId, summarizeChat],
  )
  const formatDate: typeof format = useCallback(
    (date, dateFormat) => format(date, dateFormat, {locale: dateLocales[locale]}),
    [locale],
  )
  return (
    <ExtendedDialog
      keepMounted={false}
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{translate('dialogs.chatSummaries.title')}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          {translate('dialogs.chatSummaries.subtitle')}
        </Typography>
        {!summariesLength && (
          isLoadingChatSummaries ? (
            <CircularProgress
              className={styles.circularProgress}
              size={60}
            />
          ) : (
            <div className={styles.emptyChatSummariesContainer}>
              <Typography align="center" gutterBottom variant="subtitle1">
                {isSummarizingChat ?
                  translate('dialogs.chatSummaries.isSummarizingChat') :
                  translate('dialogs.chatSummaries.empty.subtitle')
                }
              </Typography>
              <Button
                disabled={isSummarizingChat}
                onClick={() => summarizeChatMessages({
                  fromDate: formatDate(
                    addDays(addMonths(new Date(), -1), 1),
                    'yyyy-MM-dd',
                  ),
                  toDate: formatDate(new Date(), 'yyyy-MM-dd'),
                })}
                startIcon={isSummarizingChat ? <CircularProgress size={20} /> : null}
                variant="contained"
              >
                {translate('dialogs.chatSummaries.empty.summarizeAction')}
              </Button>
            </div>
          )
        )}
        {!!summariesLength && (
          <SummariesTimeline
            {...{
              formatDate,
              isSummarizingChat,
              summaries,
              summariesLength,
              summarizeChatMessages,
            }}
          />
        )}
      </DialogContent>
    </ExtendedDialog>
  )
}

const SummariesTimeline: FC<SummariesTimelineProps> = ({
  formatDate,
  isSummarizingChat,
  summaries,
  summariesLength,
  summarizeChatMessages,
}) => {
  const styles = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const [expandedAccordion, setExpandedAccordion] = useState<string>()
  const isLatestSummaryOlderThanCurrentDate = useMemo(
    () => isAfter(
      new Date(formatDate(new Date(), 'yyyy-MM-dd')),
      new Date(summaries[summariesLength - 1].toDate),
    ),
    [formatDate, summaries, summariesLength]
  )
  return (
    <>
      {(summariesLength >= 7) && (
        <ExtendedAlert className={styles.chatSummariesAlert} severity="info">
          {translate('dialogs.chatSummaries.summariesLimitReached')}
        </ExtendedAlert>
      )}
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className={styles.timelineConnector}>
              <ExtendedTooltip
                arrow
                title={translate('dialogs.chatSummaries.summarizePreviousMonth')}
              >
                <IconButton
                  color="inherit"
                  // Limiting summaries to 7 for now to avoid huge OpenAI costs.
                  disabled={isSummarizingChat || (summaries.length >= 7)}
                  onClick={() => summarizeChatMessages({
                    fromDate: formatDate(
                      addMonths(new Date(summaries[0].fromDate), -1),
                      'yyyy-MM-dd',
                    ),
                    toDate: formatDate(
                      addDays(new Date(summaries[0].fromDate), -1),
                      'yyyy-MM-dd',
                    ),
                  })}
                  size="small"
                >
                  {isSummarizingChat ? (
                    <CircularProgress color="secondary" size={24}/>
                  ) : (
                    <ChevronDownIcon style={{transform: 'rotate(180deg)'}} />
                  )}
                </IconButton>
              </ExtendedTooltip>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={styles.addMoreSummaries} />
        </TimelineItem>
        {summaries.map((summary, i) => (
          <TimelineItem key={summary.id}>
            <TimelineOppositeContent>
              <Typography color="textSecondary" variant="body2">
                {formatDate(new Date(summary.fromDate), 'PP')}
                {" - "}
                {formatDate(new Date(summary.toDate), 'PP')}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={styles.timelineConnector} />
              {((i < (summariesLength - 1)) || isLatestSummaryOlderThanCurrentDate) && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            <TimelineContent className={styles.timelineContent}>
              <Accordion
                TransitionProps={{unmountOnExit: true}}
                disabled={!summary.messagesCount}
                elevation={2}
                expanded={summary.id === expandedAccordion}
                name={summary.id}
                onChange={(_, expanded) => {
                  setExpandedAccordion(expanded ? summary.id : '')
                }}
              >
                <AccordionSummary
                  expandIcon={!!summary.messagesCount && <ChevronDownIcon />}
                >
                  <Typography variant="subtitle2">
                    {!!summary.messagesCount ?
                      translate('dialogs.chatSummaries.summaryItem.subtitle', {
                        messagesCount: summary.messagesCount,
                        title: summary.title,
                      }) :
                      translate('dialogs.chatSummaries.summaryItem.empty')
                    }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <div className={styles.summaryDetails}>
                    <IconButton
                      className={styles.summaryDetailsCopyIcon}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          summary.details.map(d => `• ${d}`).join('\n')
                        )
                        notify('dialogs.chatSummaries.summaryItem.copiedToClipboard')
                      }}
                    >
                      <FileCopyOutlined color="secondary"/>
                    </IconButton>
                    <ul>
                      {summary.details.map((d, i) => (
                        <li key={i}>
                          <Typography color="textSecondary" variant="body2">
                            {d}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Divider className={styles.divider} />
                  <Typography gutterBottom variant="subtitle2">
                    {translate('dialogs.chatSummaries.summaryItem.upsellPossibility')}
                  </Typography>
                  {summary.upsellPossibility ? (
                    <div className={styles.upsellPossibilityContainer}>
                      <LinearProgress
                        className={styles.upsellPossibilityProgressBar}
                        color="primary"
                        value={summary.upsellPossibility}
                        variant="determinate"
                      />
                      <Typography color="textSecondary" variant="body2">
                        {summary.upsellPossibility}%
                      </Typography>
                    </div>
                  ) : (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      <i>
                        {translate(
                          'dialogs.chatSummaries.summaryItem' +
                              '.upsellPossibilityMissing'
                        )}
                      </i>
                    </Typography>
                  )}
                  <Typography gutterBottom variant="subtitle2">
                    {translate(
                      'dialogs.chatSummaries.summaryItem.customerSatisfaction'
                    )}
                  </Typography>
                  {summary.customerSatisfaction ? (
                    <div className={styles.upsellPossibilityContainer}>
                      <LinearProgress
                        className={styles.upsellPossibilityProgressBar}
                        color="primary"
                        value={summary.customerSatisfaction / 5 * 100}
                        variant="determinate"
                      />
                      <Typography color="textSecondary" variant="body2">
                        {summary.customerSatisfaction} / 5
                      </Typography>
                    </div>
                  ) : (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      <i>
                        {translate(
                          'dialogs.chatSummaries.summaryItem' +
                              '.customerSatisfactionMissing'
                        )}
                      </i>
                    </Typography>
                  )}
                  <Typography gutterBottom variant="subtitle2">
                    {translate('dialogs.chatSummaries.summaryItem.actionItem')}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    {summary.actionItem}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TimelineContent>
          </TimelineItem>
        ))}
        {isLatestSummaryOlderThanCurrentDate && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={styles.timelineConnector}>
                <ExtendedTooltip
                  arrow
                  title={translate('dialogs.chatSummaries.summarizeNextMonth')}
                >
                  <IconButton
                    color="inherit"
                    disabled={isSummarizingChat || (summaries.length >= 7)}
                    onClick={() => summarizeChatMessages({
                      fromDate: formatDate(
                        addDays(new Date(summaries[summariesLength - 1].toDate), 1),
                        'yyyy-MM-dd',
                      ),
                      toDate: formatDate(
                        addMonths(new Date(summaries[summariesLength - 1].toDate), 1),
                        'yyyy-MM-dd',
                      ),
                    })}
                    size="small"
                  >
                    {isSummarizingChat ? (
                      <CircularProgress color="secondary" size={24}/>
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </IconButton>
                </ExtendedTooltip>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent className={styles.addMoreSummaries} />
          </TimelineItem>
        )}
      </Timeline>
    </>
  )
}

const CHAT_SUMMARIES_FIELDS = `
  actionItem
  chatId
  details
  fromDate
  id
  customerSatisfaction
  messagesCount
  title
  toDate
  upsellPossibility
`

const CHAT_SUMMARIES_QUERY = gql`
  query($chatId: uuid!) {
    chat_summaries(
      where: {chatId: {_eq: $chatId}}
      order_by: {fromDate: asc, toDate: asc}
    ) {${CHAT_SUMMARIES_FIELDS}}
  }
`

const SUMMARIZE_CHAT_MUTATION = gql`
  mutation($chatId: uuid!, $fromDate: date!, $toDate: date!) {
    summarizeChat(chatId: $chatId, fromDate: $fromDate, toDate: $toDate) {
      chatSummary{${CHAT_SUMMARIES_FIELDS}}
    }
  }
`

const useStyles = makeStyles(theme => ({
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  addMoreSummaries: {
    marginBottom: theme.remSpacing(18),
  },
  chatSummariesAlert: {
    margin: 'auto',
    marginBottom: theme.remSpacing(4),
    marginTop: theme.remSpacing(4),
    width: 'fit-content',
  },
  circularProgress: {
    color: theme.palette.info.main,
    display: 'flex',
    margin: 'auto',
    marginTop: theme.remSpacing(2),
  },
  divider: {
    marginBottom: theme.remSpacing(3),
    marginTop: theme.remSpacing(3),
  },
  emptyChatSummariesContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.remSpacing(3),
    paddingBottom: theme.remSpacing(5),
    paddingTop: theme.remSpacing(4),
  },
  summaryDetails: {
    backgroundColor: theme.palette.info.light,
    padding: theme.remSpacing(3),
    paddingLeft: theme.remSpacing(0),
    position: 'relative',
  },
  summaryDetailsCopyIcon: {
    cursor: 'pointer',
    padding: theme.remSpacing(0.5),
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  timelineConnector: {
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.primary.contrastText}!important`,
    },
    '&:has(.Mui-disabled)': {
      backgroundColor: theme.palette.disabled.main,
    },
    backgroundColor: theme.palette.info.main,
  },
  timelineContent: {
    marginBottom: theme.remSpacing(10),
    textAlign: 'left !important' as 'left',
  },
  upsellPossibilityContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.remSpacing(1),
    justifyContent: 'stretch',
    marginBottom: theme.remSpacing(1.5),
  },
  upsellPossibilityProgressBar: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.info.main,
    },
    '& .MuiLinearProgress-root': {
      borderRadius: 5,
      height: 10,
    },
    flexGrow: 1,
    marginBottom: 0,
  },
}))

interface SummariesTimelineProps {
  formatDate: (date: Date, f: string) => string
  isSummarizingChat: boolean,
  summaries: ChatSummaries[]
  summariesLength: number,
  summarizeChatMessages: (_: {fromDate: string, toDate: string}) => Promise<any>
}

export default ChatSummaryDialog
