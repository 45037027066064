import {useMutation, useQuery} from '@apollo/react-hooks'
import {Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, useEffect, useState} from 'react'
import {Loading, useRedirect, useTranslate} from 'react-admin'
import {useLocation, useParams} from 'react-router-dom'

import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import theme from '../theme'
import {
  Channels,
  MutationRoot,
  MutationRootStartChatArgs,
  QueryRoot,
} from '../types/graphqlSchema'
import {EmbeddedExternalChatPage} from './ExternalChatPage'

const StartExternalChatPage = () => {
  const [channelId, setChannelId] = useState<string>()
  const [chatId, setChatId] = useState<string>()
  const {
    loading: isLoadingMerchantUser, merchantUser: {id: merchantUserId} = {},
  } = useSessionMerchantUser()
  const customerUserPhoneNumber = decodeURIComponent(
    useParams<{customerUserPhoneNumber: string}>()?.customerUserPhoneNumber
  )
  const isEmbedded = (/embedded/).test(useLocation().pathname)
  const redirect = useRedirect()
  const {
    data: {channels} = {}, loading: isLoadingChannels,
  } = useQuery<QueryRoot['channels']>(
    CHANNELS_QUERY,
    {skip: !merchantUserId, variables: {merchantUserId}}
  )
  const {data: {chats} = {}, loading: isLoadingChats} = useQuery<QueryRoot['chats']>(
    CHATS_BY_PHONE_NUMBER_QUERY, {
      skip: !customerUserPhoneNumber,
      variables: {customerUserPhoneNumber},
    },
  )
  const [startChat, {loading: isLoadingChat}] = useMutation<
    MutationRoot['startChat'],
    MutationRootStartChatArgs
  >(
    START_CHAT_MUTATION,
    {onCompleted: ({startChat: {chatId} = {}}) => {setChatId(chatId)}},
  )
  useEffect(() => {
    if (isLoadingChannels) setChannelId(undefined)
    if (isLoadingChats) setChatId(undefined)
    if (chats?.length === 1) {
      setChannelId(chats[0]?.channelId)
      setChatId(chats[0]?.id)
    }
    else if (channels?.length === 1) {
      setChannelId(channels[0].id)
    }
  }, [chats, channels, isLoadingChannels, isLoadingChats])
  useEffect(() => {
    if (!channelId || !customerUserPhoneNumber) return
    startChat({
      variables: {
        chat: {
          channelId,
          customerUserWhatsappPhoneNumber: customerUserPhoneNumber,
        },
      },
    })
  }, [channelId, customerUserPhoneNumber, startChat])
  if (!customerUserPhoneNumber) return null
  if (isLoadingChannels || isLoadingChat || isLoadingMerchantUser) {
    return <Loading loadingSecondary={null} />
  }
  if (chatId && channelId) {
    if (isEmbedded) return (
      <EmbeddedExternalChatPage
        channelId={channelId}
        chatId={chatId}
      />
    )
    redirect(`/inbox/${channelId}/${chatId}`)
    return null
  }
  return (<ChannelPicker channels={channels} onSetChannelId={setChannelId} />)
}

const ChannelPicker: FC<ChannelPickerProps> = ({channels, onSetChannelId}) => {
  const styles = useStyles(theme)
  return (
    <div className={styles.channelPicker}>
      <Typography variant="h4">
        {useTranslate()('pages.startExternalChat.selectChannel')}
      </Typography>
      {
        channels?.map(c => (
          <div key={c.id}>
            <Button color="primary" onClick={() => onSetChannelId(c.id)}>
              {c.name}
            </Button>
          </div>
        ))
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  channelPicker: {
    '& .MuiButtonBase-root': {
      margin: theme.remSpacing(1),
      width: '50%',
    },
    '& .MuiTypography-root': {
      marginBottom: theme.remSpacing(3),
    },
    padding: theme.remSpacing(8),
    textAlign: 'center',
  },
}))

const CHATS_BY_PHONE_NUMBER_QUERY = gql`
  query($customerUserPhoneNumber: String!){
    chats(
      order_by: {insertionTimestamp: asc}
      where: {
        isArchived: {_eq: false}
        owningUser: {
          customerUser: {
            whatsappPhoneNumber: {_eq: $customerUserPhoneNumber}
          }
        }
      }
    ) {
      channelId
      chatMessages(limit: 1, order_by: {insertionTimestamp:desc}){insertionTimestamp}
      id
      insertionTimestamp
    }
  }
`

const CHANNELS_QUERY = gql`query($merchantUserId: uuid!){
  channels(
    order_by: {name: asc},
    where: {channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}}
  ){id name}
}`

const START_CHAT_MUTATION = gql`mutation($chat: Chat!){startChat(chat: $chat){chatId}}`

interface ChannelPickerProps {
  channels: Channels[] | undefined,
  onSetChannelId: (id: string) => void
}

export default StartExternalChatPage
