import {useMutation} from '@apollo/react-hooks'
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import _ from 'lodash'
import {FC, useContext, useMemo, useState} from 'react'
import {useNotify, useTranslate} from 'react-admin'
import {Link} from 'react-router-dom'

import ConfirmationDialog from '../../components/ConfirmationDialog'
import {
  BlockIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  FlagIcon,
  LogoutIcon,
  MagicIcon,
  MoreVertIcon,
  UserAddIcon,
} from '../../components/icons'
import {SandboxContext} from '../../contexts/SandboxContext'
import useHasPermission from '../../hooks/useHasPermission'
import {Chats, MutationRoot} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'
import ChatSummaryDialog from './ChatSummaryDialog'

const ChatContextMenuButton: FC<ChatContextMenuButtonProps> = ({
  chat: {assignedMerchantUser, id, isArchived, isSpam, owningUser, userChats},
  isExternalMultiMerchantUserChat,
}) => {
  const {isSandbox} = useContext(SandboxContext)
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles()
  const canCreateMerchantCustomerUsers = useHasPermission(
    'create', 'merchant_customer_users'
  )
  const [isChatSummaryDialogOpen, setIsChatSummaryDialogOpen] = useState(false)
  const [archive] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION, {variables: {chatId: id, setInput: {isArchived: !isArchived}}}
  )
  const [reportSpam] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION, {
      onCompleted: () => {
        setIsSpamReportDialogOpen(false)
        notify('chat.external.contextMenu.spam.success')
      },
      variables: {chatId: id, setInput: {isSpam: true}},
    }
  )
  const isFlagged = useMemo(() => _.first(userChats)?.isFlagged, [userChats])
  const [anchorElement, setAnchorElement] = useState<HTMLElement>()
  const [toggleFlagged] = useMutation(
    USER_CHATS_UPSERT_MUTATION,
    {
      optimisticResponse: {insert_user_chats_one: {
        __typename: 'user_chats', chatId: id, isFlagged: !isFlagged,
      }},
      variables: {chatId: id, isFlagged: !isFlagged},
    },
  )
  const openMenu = event => {
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }
  const closeMenu = event => {
    event.stopPropagation()
    setAnchorElement(undefined)
  }
  const [isSpamReportDialogOpen, setIsSpamReportDialogOpen] = useState(false)
  return (
    <IconButton
      aria-controls="long-menu"
      aria-haspopup="true"
      aria-label="more"
      className={classes.root}
      onClick={openMenu}
    >
      <ConfirmationDialog
        confirmationText={translate('chat.external.contextMenu.spam.title')}
        onCancel={() => setIsSpamReportDialogOpen(false)}
        onConfirm={() => {
          reportSpam()
          trackEvent('REPORT_CHAT_SPAM', EventCategory.CHAT, 'CONTEXT_MENU')
        }}
        open={isSpamReportDialogOpen}
        title={translate('chat.external.contextMenu.spam.title')}
      >
        {translate('chat.external.contextMenu.spam.confirm')}
      </ConfirmationDialog>
      <ChatSummaryDialog
        chatId={id}
        onClose={() => setIsChatSummaryDialogOpen(false)}
        open={isChatSummaryDialogOpen}
      />
      <MoreVertIcon />
      <Menu
        anchorEl={anchorElement}
        keepMounted
        onClose={closeMenu}
        open={!!anchorElement}
      >
        {!isSpam && (
          <MenuItem
            onClick={() => {
              archive()
              isArchived ?
                trackEvent('UNARCHIVE_CHAT', EventCategory.CHAT, 'CONTEXT_MENU') :
                trackEvent('ARCHIVE_CHAT', EventCategory.CHAT, 'CONTEXT_MENU')
            }}
          >
            <ListItemIcon>
              {isArchived ? <CloseIcon/> : <CheckIcon color="success" />}
            </ListItemIcon>
            <ListItemText>
              {isArchived ?
                translate('chat.external.contextMenu.unarchive') :
                translate('chat.external.contextMenu.archive')
              }
            </ListItemText>
          </MenuItem>
        )}
        {(isExternalMultiMerchantUserChat && assignedMerchantUser && !isArchived) &&
          <MenuItem
            onClick={() => {
              archive({
                variables: {
                  setInput: {assignedMerchantUserId: null, isArchived: true},
                },
              })
              trackEvent('ARCHIVE_AND_UNASSIGN_CHAT', EventCategory.CHAT, 'CONTEXT_MENU')
            }}
          >
            <ListItemIcon>
              <LogoutIcon className={classes.archiveAndUnassignIcon} color="info" />
            </ListItemIcon>
            <ListItemText>
              {translate('chat.external.contextMenu.archiveAndUnassign')}
            </ListItemText>
          </MenuItem>
        }
        {owningUser.customerUser?.merchantCustomerUsers.length ?
          <MenuItem
            component={Link}
            onClick={() => trackEvent(
              'EDIT_CONTACT',
              EventCategory.CONTACT,
              'CONTEXT_MENU'
            )}
            to={`/merchant_customer_users/${
              _.first(owningUser.customerUser?.merchantCustomerUsers)?.id
            }`}
          >
            <ListItemIcon><EditIcon color="primary" /></ListItemIcon>
            <ListItemText>
              {translate('chat.external.contextMenu.editContact')}
            </ListItemText>
          </MenuItem> :
          (canCreateMerchantCustomerUsers &&
            <MenuItem
              component={Link}
              onClick={() => trackEvent(
                'ADD_CONTACT',
                EventCategory.CONTACT,
                'CONTEXT_MENU'
              )}
              to={`/merchant_customer_users/create?whatsappPhoneNumber=${
                encodeURIComponent(owningUser.customerUser?.whatsappPhoneNumber ?? '')
              }&firstName=${
                encodeURIComponent(owningUser.customerUser?.whatsappDisplayName ?? '')
              }`}
            >
              <ListItemIcon><UserAddIcon color="primary" /></ListItemIcon>
              <ListItemText>
                {translate('chat.external.contextMenu.addContact')}
              </ListItemText>
            </MenuItem>
          )
        }
        <MenuItem
          onClick={e => {
            closeMenu(e)
            toggleFlagged()
            trackEvent('FLAG_CHAT', EventCategory.CHAT, 'CONTEXT_MENU')
          }}
        >
          <ListItemIcon><FlagIcon color="warning" /></ListItemIcon>
          <ListItemText>
            {isFlagged ?
              translate('chat.external.contextMenu.removeFlag') :
              translate('chat.external.contextMenu.addFlag')
            }
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={e => {
            closeMenu(e)
            setIsChatSummaryDialogOpen(true)
          }}
        >
          <ListItemIcon><MagicIcon /></ListItemIcon>
          <ListItemText>
            {translate('chat.external.contextMenu.viewSummaries')}
          </ListItemText>
        </MenuItem>
        {!(isSandbox || isSpam) && (
          <MenuItem onClick={() => setIsSpamReportDialogOpen(true)}>
            <ListItemIcon><BlockIcon color="background" /></ListItemIcon>
            <ListItemText>
              {translate('chat.external.contextMenu.spam.title')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </IconButton>
  )
}

const UPDATE_CHAT_MUTATION = gql`
  mutation($chatId: uuid!, $setInput: chats_set_input!) {
    update_chats_by_pk(pk_columns: {id: $chatId}, _set: $setInput){id}
  }
`

const USER_CHATS_UPSERT_MUTATION = gql`
  mutation($chatId: uuid!, $isFlagged: Boolean!) {
    insert_user_chats_one(
      object: {chatId: $chatId, isFlagged: $isFlagged}
      on_conflict: {constraint: user_chats_pkey, update_columns: [chatId, isFlagged]}
    ){chatId isFlagged}
  }
`

const useStyles = makeStyles(() => ({
  archiveAndUnassignIcon: {
    rotate: '180deg',
  },
  root: {
    zIndex: 1,
  },
}))

interface ChatContextMenuButtonProps {
  chat: Chats
  isExternalMultiMerchantUserChat: boolean
}

export default ChatContextMenuButton
