import {useMutation} from '@apollo/react-hooks'
import {Box, Theme, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {getNotification} from 'ra-core'
import {NotificationPayload} from 'ra-core/src/actions/notificationActions'
import {useEffect, useMemo} from 'react'
import {Notification as RaNotification, useTranslate} from 'react-admin'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import useIsDesktop from '../hooks/useIsDesktop'
import {ErrorIcon, InfoIcon, NotificationCheckIcon, WarningIcon} from './icons'


const ExtendedNotification = ({...props}) => {
  const notification: Notification = useSelector(getNotification)
  const styles = useStyles({notification})
  const isDesktop = useIsDesktop()
  const {pathname} = useLocation()
  const translate = useTranslate()
  const message = useMemo(() => {
    if (!notification) return
    return translate(notification.message, notification.messageArgs)
  }, [notification, translate])
  const [mutate] = useMutation(UPSERT_TOAST_MESSAGE_MUTATION)
  useEffect(() => {
    if (!notification) return
    mutate({variables: {message, pathname, type: notification.type.toString()}})
  }, [message, mutate, notification, pathname, translate])
  if (!notification) return null
  const Icon = NOTIFICATION_TYPE_TO_ICON[notification.type as string]
  return (
    <RaNotification
      anchorOrigin={{horizontal: 'center', vertical: isDesktop ? 'bottom' : 'top'}}
      autoHideDuration={5000}
      {...props}
    >
      <Box alignItems="center" className={styles.root} display="flex" gridGap="0.5rem">
        <Icon color={notification.type} />
        <Typography variant="caption">{message}</Typography>
      </Box>
    </RaNotification>
  )
}

const UPSERT_TOAST_MESSAGE_MUTATION = gql`
  mutation ($message: String, $pathname: String, $type: String) {
    insert_toast_messages_one(
      object: {message: $message, pathname: $pathname type: $type},
      on_conflict: {constraint: toast_messages_pkey, update_columns: []}
    ){message}
  }
`

const NOTIFICATION_TYPE_TO_ICON = {
  error: ErrorIcon,
  info: InfoIcon,
  success: NotificationCheckIcon,
  warning: WarningIcon,
}

const useStyles = makeStyles<Theme, MakeStylesOptions>(theme => ({
  root: ({notification}) => ({
    backgroundColor: notification && `${theme.palette[notification.type].light}`,
    border: notification && `1px solid ${theme.palette[notification.type].main}`,
    borderRadius: '10px',
    color: theme.palette.primary.main,
    marginTop: 'var(--safe-area-top-inset)',
    padding: theme.remSpacing(2),
    width: theme.remSpacing(55),
  }),
}))

type Notification = Omit<NotificationPayload, 'notificationOptions'> &
  NotificationPayload['notificationOptions']

interface MakeStylesOptions {
  notification: Notification | undefined
}

export default ExtendedNotification
