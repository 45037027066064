import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback, useMemo} from 'react'
import {useTranslate} from 'react-admin'

import {ChatMessages, QueryRoot} from '../types/graphqlSchema'

const useCompileChatMessageText = () => {
  const translate = useTranslate()
  const {
    data: {
      chat_message_template_placeholder_types:
      chatMessageTemplatePlaceholderTypes = [],
    } = {},
  } = useQuery<QueryRoot['chat_message_template_placeholder_types']>(
    CHAT_MESSAGE_TEMPLATE_PLACEHOLDER_TYPES_QUERY,
  )
  const placeholderTypeToLabel: PlaceholderTypeToLabel = useMemo(() => (
    chatMessageTemplatePlaceholderTypes?.reduce(
      (typeToTranslation, placeholder) => ({
        ...typeToTranslation,
        [placeholder.type]: translate(
            `chat_message_template_placeholder_types.${placeholder.type}`
        ),
      }),
      {}
    ) ?? {}
  ), [chatMessageTemplatePlaceholderTypes, translate])
  const compileChatMessageText = useCallback(
    (chatMessage?: Partial<ChatMessages>): string|undefined => {
      const filledPlaceholderCompiledText = chatMessage?.text ??
        chatMessage?.chatMessageTemplateValues?.reduce?.(
          (compiledText, {chatMessageTemplatePlaceholder: {index, type}, value}) => (
            // Only replace the placeholder if `value` isn't `undefined/null or `""`. The
            // latter is the case for template messages where the user didn't enter all
            // values yet before inserting the template message.
            !!value ?
              compiledText
                ?.replaceAll?.(`{{${index}}}`, value)
                // Replacing smart placeholders with their corresponding values in order
                // to show the value instead of the smart placeholder
                ?.replaceAll?.(`{{%${type}%}}`, value) :
              compiledText
          ),
          chatMessage.chatMessageTemplate?.text
        )
      return chatMessage?.chatMessageTemplate?.chatMessageTemplatePlaceholders?.filter(
        p => !!p.type
      ).reduce(
        (compiledText, {index, type}) => (
          compiledText
            ?.replaceAll?.(`{{${index}}}`, `{{%${placeholderTypeToLabel[type!]}%}}`)
        ), filledPlaceholderCompiledText
      ) ?? filledPlaceholderCompiledText
    },
    [placeholderTypeToLabel]
  )
  return {compileChatMessageText, placeholderTypeToLabel}
}


const CHAT_MESSAGE_TEMPLATE_PLACEHOLDER_TYPES_QUERY = gql`
  query {
    chat_message_template_placeholder_types{type}
  }
`

interface PlaceholderTypeToLabel {
  [type_: string]: string;
}

export {useCompileChatMessageText}
