import {useQuery} from '@apollo/react-hooks'
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import _ from 'lodash'
import {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Loading, useTranslate} from 'react-admin'

import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import theme from '../../theme'
import {
  ChatMessageTemplateValue,
  Maybe,
  QueryRoot,
  Scalars,
} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'
import {ChatMessageTemplatePreview}
  from '../chatMessageTemplate/ChatMessageTemplateEditor'
import ChatContext from './ChatContext'

const ChatMessageTemplateBuilder: FC<ChatMessageTemplateBuilderProps> = ({
  onChangePlaceholders,
  onChangeTemplateId,
  onValidatePlaceholders,
  templateId,
}) => {
  const translate = useTranslate()
  const styles = useStyles(theme)
  const {channelId} = useContext(ChatContext)
  const {compileChatMessageText} = useCompileChatMessageText()
  const {
    data: {whatsapp_message_templates: whatsappMessageTemplates} = {},
    loading,
  } = useQuery<QueryRoot['whatsapp_message_templates']>(
    WHATSAPP_MESSAGE_TEMPLATES_QUERY,
    {fetchPolicy: 'cache-and-network', variables: {channelId}},
  )
  const chatMessageTemplates = whatsappMessageTemplates?.map(w =>
    w.chatMessageTemplate
  )
  const [canSendTemplate, setCanSendTemplate] = useState(false)
  const [templatePlaceholderIndexToValue, setTemplatePlaceholderIndexToValue] =
    useState<TemplatePlaceholderIndexToValue>({} as TemplatePlaceholderIndexToValue)
  const selectedChatMessageTemplate = useMemo(() => chatMessageTemplates?.find(
    ({id}) => id === templateId
  ), [chatMessageTemplates, templateId])
  const renderedTemplateMessage = useMemo(
    () => compileChatMessageText({
      chatMessageTemplate: selectedChatMessageTemplate,
      // @ts-ignore
      chatMessageTemplateValues: Object.entries(templatePlaceholderIndexToValue)
        .map(([index, {placeholderId, value}]) => ({
          chatMessageTemplatePlaceholder: {index},
          chatMessageTemplatePlaceholderId: placeholderId,
          value,
        })),
    }),
    [compileChatMessageText, selectedChatMessageTemplate, templatePlaceholderIndexToValue]
  )
  const setPlaceholderValue = useCallback((index, value, placeholderId, error) => {
    setTemplatePlaceholderIndexToValue(prevState =>
      ({...prevState, [index]: {error, placeholderId, value}})
    )
  }, [setTemplatePlaceholderIndexToValue])
  useEffect(
    () => {
      setCanSendTemplate(
        !!selectedChatMessageTemplate?.chatMessageTemplatePlaceholders.every(
          ({index}) => !templatePlaceholderIndexToValue[index]?.error
        ))
      onValidatePlaceholders(canSendTemplate)
    },
    [
      canSendTemplate,
      onValidatePlaceholders,
      selectedChatMessageTemplate?.chatMessageTemplatePlaceholders,
      templatePlaceholderIndexToValue,
    ]
  )
  useEffect(
    () => {
      onChangePlaceholders(Object.entries(templatePlaceholderIndexToValue)
        .reduce(
          (placeholders, [, {placeholderId, value}]) => (
            value?.trim() ? [...placeholders, {
              chatMessageTemplatePlaceholderId: placeholderId,
              value,
            }] : placeholders
          ),
          [] as {chatMessageTemplatePlaceholderId: string, value: string}[],
        )
      )
    },
    [onChangePlaceholders, templatePlaceholderIndexToValue]
  )
  useEffect(
    () => {
      if (!templateId && chatMessageTemplates?.length) {
        onChangeTemplateId(_.first(chatMessageTemplates)!.id)
      }
    },
    [chatMessageTemplates, onChangeTemplateId, templateId]
  )
  if (loading) return <Loading className={`${styles.loadingState}`} />
  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={3}>
          <Typography className={styles.inputLabels} variant="subtitle2">
            {translate('dialogs.templateMessageComposer.templateBuilder.chooseTemplate')}
          </Typography>
        </Grid>
        <Grid item lg={9} xs={12}>
          <FormControl className={styles.formControl} variant="outlined">
            <Select
              onChange={event => {
                onChangeTemplateId(event.target.value as string)
                setTemplatePlaceholderIndexToValue({} as TemplatePlaceholderIndexToValue)
                trackEvent('SELECT_TEMPLATE', EventCategory.TEMPLATE)
              }}
              value={templateId ?? '' /* empty string required per component spec */}
              variant="filled"
            >
              {chatMessageTemplates?.map(({id, name}) =>
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {!!selectedChatMessageTemplate?.chatMessageTemplatePlaceholders.length &&
        <Typography className={styles.inputLabelsTitle} variant="subtitle2">
          {translate('dialogs.templateMessageComposer.templateBuilder.fillPlaceholder')}
        </Typography>
      }
      {
        selectedChatMessageTemplate?.chatMessageTemplatePlaceholders.map(
          templatePlaceholder => (
            <ChatMessageTemplatePlaceholder
              key={templatePlaceholder.id}
              onChange={setPlaceholderValue}
              value={templatePlaceholderIndexToValue[templatePlaceholder.index]?.value}
              {...templatePlaceholder}
            />
          )
        )
      }
      <Grid container spacing={1}>
        <Grid item lg={3} xs={12}>
          <Typography className={styles.inputLabels} component="h5">
            {translate('actions.preview')}
          </Typography>
        </Grid>
        <Grid item lg={9} xs={12}>
          <ChatMessageTemplatePreview
            chatMessageTemplateId={templateId as string}
            className={styles.chatMessageTemplatePreview}
            isPropertiesPaneVisible={false}
            text={renderedTemplateMessage}
          />
        </Grid>
      </Grid>
    </>
  )
}

// Renders a placeholder row. If `value` is `undefined` this component assumes that the
// user hasn't entered any data yet and omits displaying an "empty input" message. Once
// the user however manually deletes the input text, `value` will turn into an empty
// string `""` so that a "empty input" error message will be rendered.
const ChatMessageTemplatePlaceholder: FC<ChatMessageTemplatePlaceholderProps> = (
  {id, index, label, maxLength, onChange, type, value}
) => {
  const styles = useStyles()
  const translate = useTranslate()
  const formattedLabel = useMemo(() => (
    type !== 'FREE_TEXT' ? `%${translate(
      `chat_message_template_placeholder_types.${type}`
    )}%` : label
  ), [label, type, translate])
  const error = (() => {
    if (type === 'FREE_TEXT' && !value?.trim().length) {
      return translate(
        'chatMessageTemplateBuilder.templatePlaceholder.errors.labelRequired',
        {formattedLabel},
      )
    }
    if ((value?.trim().length ?? 0) > maxLength) {
      return translate(
        'chatMessageTemplateBuilder.templatePlaceholder.errors.maxLabelLength',
        {formattedLabel, maxLength},
      )
    }
  })()
  useEffect(
    () => {onChange(index, value, id, error)},
    [index, value, id, error, onChange]
  )
  return (
    <Grid container spacing={1}>
      <Grid item lg={3}>
        <Typography className={styles.placeholderLabels} variant="body1">
          {formattedLabel}
        </Typography>
      </Grid>
      <Grid item lg={9} xs={12}>
        <TextField
          className={styles.formControl}
          error={(value !== undefined) && !!error}
          helperText={(value !== undefined) && error}
          id="outlined-multiline-static"
          onChange={e => {onChange(index, e.target.value, id, error)}}
          placeholder={formattedLabel || ''}
          value={value ?? ""}
          variant="filled"
        />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  actionButton: {
    '& > *': {
      maxHeight: '18px',
      minWidth: '64px',
    },
  },
  buttonGroup: {
    marginTop: '50px',
  },
  chatMessageTemplatePreview: {
    '& > div': {
      width: '100%',
    },
    margin: theme.remSpacing(1),
  },
  content: {
    lineHeight: '1.75rem',
    marginBottom: '50px',
  },
  dialog: {
    '& .MuiDialog-paper': {
      outline: 'none',
      padding: '38px 32px !important',
    },
  },
  expiredMessage: {
    marginLeft: '1rem',
  },
  formControl: {
    [theme.breakpoints.down('md')]: {
      margin: `${theme.remSpacing(1)} 0`,
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
    '& textarea, .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.dark,
    },
    margin: theme.remSpacing(1),
    width: '100%',
  },
  headerMedia: {
    marginLeft: theme.remSpacing(1.5),
    marginTop: theme.remSpacing(2.5),
    width: '100%',
  },
  inputLabels: {
    marginTop: '20px',
  },
  inputLabelsTitle: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  loadingState: {
    height: '30%',
    marginTop: '15px',
  },
  placeholderLabels: {
    [theme.breakpoints.up('lg')]: {
      lineHeight: theme.remSpacing(7.5),
      margin: `${theme.remSpacing(1)} 0`,
    },
    marginTop: '20px',
  },
  templateMessageIcon: {
    backgroundColor: '#ffffff !important',
    margin: '0 0 0 10px !important',
  },
}))

const WHATSAPP_MESSAGE_TEMPLATES_QUERY = gql`
  query($channelId: uuid!){
    whatsapp_message_templates(
      order_by: {
        chatMessageTemplate: {
          name: asc
        }
      }
      where: {
        chatMessageTemplate: {
          isDeleted: {_eq: false}
          isListed: {_eq: true}
        }
        status: {_eq: APPROVED}
        whatsappBusinessAccount: {
          whatsappAccounts: {
            channel: {id: {_eq: $channelId}}
          }
        }
      }
    ){
      chatMessageTemplate{
        chatMessageTemplatePlaceholders{
          id
          index
          label
          maxLength
          type
        }
        headerMediaKey
        id
        name
        text
      }
    }
  }
`

interface ChatMessageTemplateBuilderProps {
  onChangePlaceholders: (placeholders: any[]) => void
  onChangeTemplateId: (templateId: string) => void
  onValidatePlaceholders: (isValid: boolean) => void
  templateId?: string
}

interface TemplatePlaceholderIndexToValue extends ChatMessageTemplateValue {
  placeholderId: string
}

interface ChatMessageTemplatePlaceholderProps {
  [k: string]: any
  id: string
  index: Scalars['Int']
  label?: Maybe<string>
  maxLength: Scalars['Int']
  onChange: (
    index: number,
    value: string,
    placeholderId: string,
    error?: string
  ) => void
  value: string
}

export default ChatMessageTemplateBuilder
