import {useQuery} from '@apollo/react-hooks'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Autocomplete} from '@material-ui/lab'
import {ExecutionResult} from 'graphql'
import gql from 'graphql-tag'
import {FC, ReactNode, useCallback, useMemo, useState} from 'react'
import {useGetIdentity, useTranslate} from 'react-admin'

import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import {Chats, ChatStart, MerchantUsers, QueryRoot} from '../../types/graphqlSchema'
import DialogCloseButton from '../DialogCloseButton'
import ExtendedDialog from '../ExtendedDialog'
import {UserGroupIcon, UserIcon} from '../icons'

const StartChatDialog: FC<StartChatDialogProps> = ({
  actionButtonText,
  children,
  hasInternalChats = false,
  isChatNameEditable = true,
  loading,
  onClose,
  onStartChat,
  open,
  title,
}) => {
  const translate = useTranslate()
  const userDisplayName = useUserDisplayName()
  const getChatName = useGetChatName()
  const styles = useStyles()
  const {identity: {id: userId} = {}} = useGetIdentity()
  const [name, setName] = useState<string>()
  const {
    data: {merchant_users: merchantUsers} = {}, loading: isLoadingMerchantUsers,
  } = useQuery<QueryRoot['merchant_users']>(
    MERCHANT_USERS_QUERY,
    {skip: !(open && userId), variables: {userId}}
  )
  const {
    data: {chats: internalChats} = {}, loading: isLoadingInternalChats,
  } = useQuery<QueryRoot['chats']>(
    INTERNAL_CHATS_QUERY,
    {skip: !(open && userId && hasInternalChats), variables: {userId}}
  )
  const [
    selectedInternalChatsOrMerchantUsers,
    setSelectedInternalChatsOrMerchantUsers,
  ] = useState<ChatOrMerchantUser[]>([])
  const internalChatsOrMerchantUsers = useMemo(() => {
    const multiMerchantUsersInternalChats =
      internalChats?.filter(c => c.chatUsers.length > 1)
    return [...(merchantUsers ?? []), ...(multiMerchantUsersInternalChats ?? [])]
  }, [internalChats, merchantUsers])
  const filterByMatchingChatOrMerchantUserNames = useCallback(
    (internalChatsOrMerchantUsers: ChatOrMerchantUser[], pattern: string) =>
      internalChatsOrMerchantUsers.filter(chatOrMerchantUser => {
        if (chatOrMerchantUser.__typename === 'chats') {
          return getChatName(chatOrMerchantUser).toLowerCase().includes(
            pattern.toLowerCase()
          )
        }
        if (chatOrMerchantUser.__typename === 'merchant_users') {
          return userDisplayName({merchantUser: chatOrMerchantUser})?.toLowerCase()
            .includes(pattern.toLowerCase())
        }
        throw new Error(`Unexpected object type: ${chatOrMerchantUser.__typename}`)
      }),
    [getChatName, userDisplayName]
  )
  const canStartChat = (
    !!selectedInternalChatsOrMerchantUsers.length &&
    !(isLoadingInternalChats || isLoadingMerchantUsers || loading)
  )
  return (
    <ExtendedDialog
      aria-labelledby="form-dialog-title"
      disableBackdropClick={loading}
      keepMounted
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        {children}
        {isChatNameEditable && (
          <>
            <DialogContentText color="textPrimary">
              {translate('dialogs.startChat.internal.form.chatName.label')}
            </DialogContentText>
            <TextField
              onChange={event => setName(event.target.value)}
              value={name}
              variant="filled"
            />
          </>
        )}
        <DialogContentText color="textPrimary">
          {translate('dialogs.startChat.internal.form.teamMembers.label')}*
        </DialogContentText>
        <Autocomplete<ChatOrMerchantUser, true, boolean, undefined>
          ListboxProps={{style: {maxHeight: '30vh'}}}
          filterOptions={(chatsOrMerchantUsers, {inputValue}) => {
            // If the chat is selected, it's not possible to select anything else, the
            // options list is empty.
            if (selectedInternalChatsOrMerchantUsers.some(
              ({__typename}) => __typename === 'chats'
            )) return []
            // If a merchant user is selected, only merchant users can be selected in
            // addition. The options list contains only merchant users.
            if (selectedInternalChatsOrMerchantUsers.some(
              ({__typename}) => __typename === 'merchant_users'
            )) {
              return filterByMatchingChatOrMerchantUserNames(
                chatsOrMerchantUsers.filter(
                  ({__typename}) => __typename === 'merchant_users'
                ),
                inputValue
              )
            }
            return filterByMatchingChatOrMerchantUserNames(
              chatsOrMerchantUsers,
              inputValue
            )
          }}
          filterSelectedOptions
          multiple
          onChange={(_, chatsOrMerchantUsers) =>
            setSelectedInternalChatsOrMerchantUsers(chatsOrMerchantUsers)
          }
          options={internalChatsOrMerchantUsers}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('dialogs.startChat.internal.form.teamMembers.placeholder')}
              required
              variant="filled"
            />
          )}
          renderOption={chatOrMerchantUser => (
            <ChatOrMerchantUserNameBox chatOrMerchantUser={chatOrMerchantUser} />
          )}
          renderTags={chatsOrMerchantUsers =>
            chatsOrMerchantUsers.map(chatOrMerchantUser => (
              <Chip
                className={styles.chip}
                key={chatOrMerchantUser.id}
                label={
                  <ChatOrMerchantUserNameBox chatOrMerchantUser={chatOrMerchantUser} />
                }
                onDelete={() => setSelectedInternalChatsOrMerchantUsers(
                  chatsOrMerchantUsers =>
                    chatsOrMerchantUsers.filter(({id}) => id !== chatOrMerchantUser.id)
                )}
                variant="outlined"
              />
            ))
          }
          value={selectedInternalChatsOrMerchantUsers}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!canStartChat}
          onClick={() =>
            onStartChat(
              {
                name,
                userIds: selectedInternalChatsOrMerchantUsers.flatMap(
                  chatOrMerchantUser => ({
                    chats: () => (chatOrMerchantUser as Chats)
                      .chatUsers.map(cu => cu.user.id),
                    merchant_users: () => (chatOrMerchantUser as MerchantUsers).user?.id,
                  }[chatOrMerchantUser.__typename as string])()
                ),
              }
            ).then(() => setSelectedInternalChatsOrMerchantUsers([]))
          }
          variant="contained"
        >
          {loading ? <CircularProgress size={16} /> : actionButtonText}
        </Button>
      </DialogActions>
    </ExtendedDialog>
  )
}

const ChatOrMerchantUserNameBox: FC<ChatOrMerchantUserNameBoxProps> = ({
  chatOrMerchantUser,
}) => {
  const userDisplayName = useUserDisplayName()
  const getChatName = useGetChatName()
  const name = useMemo(
    () => ({
      chats: () => getChatName(chatOrMerchantUser as Chats),
      merchant_users: () => userDisplayName({
        merchantUser: chatOrMerchantUser as MerchantUsers,
      }),
    }[chatOrMerchantUser.__typename as string]()),
    [chatOrMerchantUser, getChatName, userDisplayName],
  )
  return (
    <Box
      alignItems="center"
      className={useStyles()?.chatOrMerchantUserNameBox}
      display="flex"
    >
      {chatOrMerchantUser.__typename === 'chats' ? <UserGroupIcon /> : <UserIcon />}
      <Typography>{name}</Typography>
    </Box>
  )
}

const useGetChatName = () => {
  const userDisplayName = useUserDisplayName()
  return useCallback((chat: Chats) => (
    chat.name ??
    chat.chatUsers
      .map(cu => userDisplayName({merchantUser: cu.user.merchantUser}))
      .join(", ")
  ), [userDisplayName])
}

const MERCHANT_USERS_QUERY = gql`query($userId: uuid!) {
  merchant_users(where: {user: {id: {_neq: $userId}}}) {firstName id lastName user{id}}
}`


const INTERNAL_CHATS_QUERY = gql`query($userId: uuid!){
  chats(where: {isProductNews: {_eq: False}, type: {_eq: INTERNAL}}){
    chatUsers(where: {userId: {_neq: $userId}}){
      user{id merchantUser{firstName id lastName user{id}}}
    }
    id
    name
  }
}`

const useStyles = makeStyles(theme => ({
  chatOrMerchantUserNameBox: {
    '& svg': {
      color: theme.palette.text.secondary,
      marginRight: theme.remSpacing(1),
    },
  },
  chip: {
    '& .MuiChip-label': {
      whiteSpace: 'pre-wrap',
    },
    height: 'unset',
    padding: theme.remSpacing(.5),
  },
}))

interface OnStartChatProps {
  name: string | undefined
  userIds: string[]
}

interface StartChatDialogProps {
  actionButtonText: string
  children?: ReactNode
  hasInternalChats?: boolean
  isChatNameEditable?: boolean
  loading?: boolean
  onClose: () => void
  onStartChat: (props: OnStartChatProps) =>
    Promise<ExecutionResult<{startChat?: ChatStart}>>
  open: boolean
  title: string
}

interface ChatOrMerchantUserNameBoxProps {
  chatOrMerchantUser: ChatOrMerchantUser
}

type ChatOrMerchantUser = Chats | MerchantUsers

export default StartChatDialog
