import {useMutation} from '@apollo/react-hooks'
import {Grid, Link, makeStyles, Typography} from '@material-ui/core'
import {FORM_ERROR} from 'final-form'
import gql from 'graphql-tag'
import {useState} from 'react'
import {Loading, useTranslate} from 'react-admin'
import {Redirect} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedFinalForm'
import RichTranslationText from '../components/RichTranslationText'
import theme from '../theme'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {actionErrorCode} from '../utils/errors'

const ForgotPasswordPage = () => {
  const translate = useTranslate()
  const styles = useStyles()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const [isResetRequestSent, setIsResetRequestSent] = useState(false)
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET_MUTATION)
  const validate = values => ({
    ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
      emailAddress: translate('validations.emailAddress'),
    }),
  })
  const submit = variables => requestPasswordReset({variables})
    .then(() => setIsResetRequestSent(true))
    .catch(e => {
      const errorCode = actionErrorCode(e)
      const errorMessage = translate(
        errorCode ? `errors.codes.${errorCode}` : 'ra.message.error'
      )
      return {[FORM_ERROR]: errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return (
    <EmptyLayout>
      {isResetRequestSent ? (
        <Grid
          alignItems="stretch"
          className={styles.container}
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Grid item>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.requestPasswordReset.resetRequestSent.title')}
            </Typography>
          </Grid>
          <Grid className={styles.requestResetSuccessful} item>
            <Typography align="center" color="textSecondary" gutterBottom>
              {translate('pages.requestPasswordReset.resetRequestSent.subtitle')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              {translate('pages.requestPasswordReset.resetRequestSent.warn')}
            </Typography>
            <Typography align="center" color="textSecondary">
              <RichTranslationText
                tags={{
                  link: match => (
                    <Link
                      color="secondary"
                      onClick={() => setIsResetRequestSent(false)}
                      underline="none"
                    >
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.resetRequestSent.resend"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center">
              <Link color="secondary" href="/login" underline="none">
                {translate('pages.requestPasswordReset.resetRequestSent.login')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Form submit={submit} validate={validate}>
          <Grid alignItems="stretch" container direction="column" item spacing={3}>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.requestPasswordReset.title')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              <RichTranslationText
                tags={{
                  register: match => (
                    <Link href="/signup" underline="none" variant="body1">
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.register"
              />
            </Typography>
          </Grid>
          <Grid alignItems="stretch" container direction="column" item spacing={3}>
            <SubmitErrorMessage />
            <Grid item>
              <TextField
                label={translate('pages.requestPasswordReset.inputs.emailAddress.label')}
                name="emailAddress"
                parse={v => v?.toLowerCase() ?? ''}
                placeholder={
                  translate('pages.requestPasswordReset.inputs.emailAddress.placeholder')
                }
                type="email"
              />
            </Grid>
            <Grid item>
              <SubmitButton>
                {translate('pages.requestPasswordReset.submit')}
              </SubmitButton>
            </Grid>
          </Grid>
          <Grid item>
            <Typography align="center" color="textPrimary" variant="body1">
              <RichTranslationText
                tags={{
                  login: match => (
                    <Link href="/login" underline="none"> {match}</Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.login"
              />
            </Typography>
          </Grid>
        </Form>
      )}
    </EmptyLayout>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: '100%',
    margin: 'auto',
    width: theme.remSpacing(56),
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  requestResetSuccessful: {
    alignSelf: 'center',
    width: theme.remSpacing(45),
  },
}))

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation($emailAddress: String!){requestPasswordReset(emailAddress: $emailAddress)}
`

export default ForgotPasswordPage
