import {
  Datagrid,
  Empty,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin'

import {useUserDisplayName} from '../hooks/useUserDisplayName'
import {MerchantUsers} from '../types/graphqlSchema'

const OrganizationMerchantUsersList = () => {
  const userDisplayName = useUserDisplayName()
  return (
    <List
      actions={false}
      basePath="/"
      bulkActionButtons={false}
      exporter={false}
      filter={{isOwner: true}}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      perPage={10}
      resource="organization_merchant_users"
      sort={{field: 'merchant.name', order: 'ASC'}}
    >
      <Datagrid empty={<Empty />}>
        <ReferenceField
          reference="merchants"
          sortBy="merchant.name"
          sortByOrder="ASC"
          source="merchantId"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField<MerchantUsers>
          label={useTranslate()(
            'resources.organization_merchant_users.customFields.name.label'
          )}
          render={merchantUser => userDisplayName({merchantUser})}
          sortBy="firstName"
          sortByOrder="ASC"
        />
        <TextField source="emailAddress" />
      </Datagrid>
    </List>
  )
}

export default OrganizationMerchantUsersList
