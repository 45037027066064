import {QueryHookOptions, QueryResultWrapper, useQuery} from '@apollo/react-hooks'
import {NetworkStatus} from 'apollo-client'
import gql from 'graphql-tag'
import _ from 'lodash'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {usePageVisibility} from 'react-page-visibility'

import {Chats, QueryRoot} from '../../types/graphqlSchema'
import useSessionMerchantUser from '../useSessionMerchantUser'

// TODO: Generalize into a useSubscribedQuery() hook once sorting + favicon are moved out
/* This hook firstly runs a graphql query and then subscribes for more results
 * Advantage over using a subscription only: queries are replied immediately while the
 * first subscription response can take a while to come in. So this hook can be used
 * where time to first data is crucial for UX.
 */
const useChatsQueryAndSubscription = (
  chatsBoolExpQueryFilters: Record<string, any>,
  chatFields: string,
  chatFilter: ChatFilter,
  options?: QueryHookOptions,
) => {
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const isPageVisible = usePageVisibility() ?? true
  // Cursor should not be reinitialize on re-render, otherwise the subscription will be
  // constantly updated, and we will never receive any new data via the subscription.
  const cursor = useRef(new Date().toISOString())
  const [hasEarlierChats, setHasEarlierChats] = useState(true)
  // Offset value before initially loading any chats
  const chatOffsetRef = useRef<number>(CHATS_LOAD_MORE_OFFSET)
  const queryDocument = useMemo(() => gql`
    query($offset: Int, $where: chats_bool_exp!){
      chats(
        limit: ${CHATS_LOAD_MORE_OFFSET}
        offset: $offset
        order_by: {lastActivityTimestamp: desc}
        where: $where
      ){${chatFields}}
    }
  `, [chatFields])
  const extendedQueryOptions = useMemo<QueryHookOptions>(() => ({
    ...options,
    variables: {
      ...options?.variables,
      where: {...chatsBoolExpQueryFilters, ...options?.variables?.where},
    },
  }), [chatsBoolExpQueryFilters, options])
  const chatsEventLogsStreamDocument = useMemo(() => gql`
    subscription($cursor: timestamptz!, $limit: Int!, $where: chats_event_logs_bool_exp!){
      chats_event_logs_stream(
        batch_size: $limit
        cursor: {initial_value: {insertionTimestamp: $cursor}, ordering: ASC}
        where: $where
      ){chat{${chatFields}} id}
    }
  `, [chatFields])
  const {
    data: {chats} = {},
    loading,
    error,
    fetchMore,
    networkStatus,
    subscribeToMore,
  } = useQuery<QueryRoot['chats']>(queryDocument, extendedQueryOptions)
  useEffect(() => {
    // Don't subscribe (and effectively also unsubscribe) if page isn't visible in browser
    if (extendedQueryOptions.skip || !isPageVisible || !sessionMerchantUserId) return
    // @ts-ignore
    return subscribeToMore({
      document: chatsEventLogsStreamDocument,
      // @ts-ignore
      updateQuery: (prev, {subscriptionData: {data} = {}}) => {
        // @ts-ignore
        const incomingChats = data?.chats_event_logs_stream?.map(({chat}) => chat)
        return {
          chats: _.orderBy(
            _.unionBy(
              incomingChats as Chats[], prev?.chats, 'id'
            ).filter(
              ({assignedMerchantUser, isArchived}) => ({
                all: !isArchived,
                archived: isArchived,
                mine: (assignedMerchantUser?.id === sessionMerchantUserId) && !isArchived,
                unassigned: !assignedMerchantUser && !isArchived,
              }[chatFilter])
            ),
            'lastActivityTimestamp',
            'desc',
          ),
        }
      },
      variables: {
        cursor: cursor.current,
        /*
          Hasura not only bundles multiple chat insert events into a single
          notification event. It also hands over _all_ chats matching the subscription
          query, being them newly inserted chats or already existing ones. This said,
          Hasura doesn't just give us the new rows, it gives _all_ rows which match the
          query. This said, we need to specify how many rows we want to fetch at most per
          subscription notification. Hence, the `limit` specifies how many inserted chats
          we expect at most to come in "at the same time". In case there were more rows
          inserted "simultaneously" we would get only the amount specified by `limit`,
          missing any new chat exceeding the limit.
        */
        limit: 50,
        where: {
          chat: {
            ...(chatsBoolExpQueryFilters.channelId &&
              {channelId: chatsBoolExpQueryFilters.channelId}),
            ...(chatsBoolExpQueryFilters.type && {type: chatsBoolExpQueryFilters.type}),
          },
        },
      },
    })
  }, [
    chatFilter,
    chatsBoolExpQueryFilters,
    chatsEventLogsStreamDocument,
    extendedQueryOptions.skip,
    isPageVisible,
    sessionMerchantUserId,
    subscribeToMore,
  ])
  const fetchEarlierChats = useCallback(async () => {
    try {
      await fetchMore({
        updateQuery: (
          {chats: previousChats = []} = {} as any,
          {fetchMoreResult: {chats: moreChats} = {}},
        ) => {
          if (moreChats?.length) chatOffsetRef.current += CHATS_LOAD_MORE_OFFSET
          moreChats && setHasEarlierChats(!!moreChats.length)
          return {
            chats: _.unionBy(previousChats, moreChats, 'id'),
          } as QueryResultWrapper<QueryRoot['chats']>
        },
        variables: {offset: chatOffsetRef.current},
      })
    }
    catch (error) {
      // Invariant violation: 17 is thrown when component is unmounted while query is
      // still in progress, e.g. when user navigates to different channel/page.
      // See: https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
      if ((error as Error).toString().includes('Invariant Violation')) {
        return
      }
      throw error
    }
  }, [fetchMore])
  const onResetFetchEarlierChatsOffset = useCallback(() => {
    chatOffsetRef.current = CHATS_LOAD_MORE_OFFSET
    setHasEarlierChats(true)
  }, [setHasEarlierChats])
  return {
    chats,
    error,
    fetchEarlierChats,
    hasEarlierChats,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
    loading,
    onResetFetchEarlierChatsOffset,
  }
}

export const CHATS_LOAD_MORE_OFFSET = 20

export default useChatsQueryAndSubscription
