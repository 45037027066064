import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  Paper,
  Popper,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@material-ui/lab'
import {difference} from 'lodash'
import {
  FC, MouseEventHandler, ReactElement, ReactNode,
  useCallback, useEffect, useRef, useState,
} from 'react'
import {
  CreateButton,
  EditContextProvider,
  List,
  ListProps,
  SimpleForm,
  TopToolbar,
  useChoices,
  useListContext,
  useReference,
  useResourceContext,
  useTranslate,
} from 'react-admin'
import {useForm} from 'react-final-form'

import DisplayBox from '../../components/DisplayBox'
import EditFormAutoSave from '../../components/EditFormAutoSave'
import Empty from '../../components/Empty'
import {AddIcon, CloseIcon} from '../../components/icons'
import useHasPermission from '../../hooks/useHasPermission'
import useSelectAllRowIdsInAllPages from '../../hooks/useSelectAllRowIdsInAllPages'
import Theme from '../../theme'
import ResourceTitle from './ResourceTitle'

const ExtendedList: FC<ExtendedListProps> = ({
  actionButtons,
  canSelectAllPages = false,
  description,
  filters,
  onCreateButtonClick,
  ...props
}) => {
  const {actions, title} = props
  const hasFilters = !!filters
  const translate = useTranslate()
  const resource = useResourceContext()
  const styles = useStyles({hasFilters})
  return (
    <List
      actions={
        <ExtendedListHeader
          actionButtons={actionButtons}
          description={description}
          hasFilters={hasFilters}
          onCreateButtonClick={onCreateButtonClick}
          title={title}
        />
      }
      className={styles.root}
      component={({children}) => (
        <WrapperComponent {...{canSelectAllPages, children}} />
      )}
      {...props}
      empty={
        <TopToolbar>
          <Box width="100%">
            {actions ?? (
              <ExtendedListHeader
                actionButtons={actionButtons}
                description={description}
                hasFilters={hasFilters}
                onCreateButtonClick={onCreateButtonClick}
              />
            )}
            <Empty {...props}/>
          </Box>
        </TopToolbar>
      }
      filters={filters && <FiltersForm filters={filters} {...props} />}
      title={
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {title ?? translate(`resources.${resource}.name`)}
          <ExtendedListActions
            actionButtons={actionButtons}
            onCreateButtonClick={onCreateButtonClick}
          />
        </Box>
      }
    />
  )
}

const WrapperComponent = ({canSelectAllPages, children}) => {
  const {loading: isPageLoading, total} = useListContext()
  const translate = useTranslate()
  const styles = useStyles({})
  const {
    isAllRowIdsInAllPagesSelected,
    loading: isAllPagesRowIdsLoading,
    selectAllRowIdsInAllPages,
    unselectAllRowIdsInAllPages,
  } = useSelectAllRowIdsInAllPages({isEnabled: canSelectAllPages})
  return (
    <Box width="100%">
      {(canSelectAllPages && !!total) && (
        <FormControlLabel
          className={styles.selectAllPagesField}
          control={
            <Checkbox
              checked={isAllRowIdsInAllPagesSelected}
              color="primary"
              disabled={isAllPagesRowIdsLoading}
              icon={
                isAllPagesRowIdsLoading ? <CircularProgress size="24px" /> : undefined
              }
              onChange={e => e.target.checked ?
                selectAllRowIdsInAllPages() :
                unselectAllRowIdsInAllPages()
              }
            />
          }
          label={translate('extendedList.selectAllPages')}
        />
      )}
      {isPageLoading && (
        <Box display="flex" flexDirection="column" gridGap="1.5rem" paddingX="2.5rem">
          {[...Array(12).keys()]
            .map(i => <Skeleton height="3.5rem" key={i} variant="rect" />)}
        </Box>
      )}
      {!isPageLoading && (total ? children : <Empty />)}
    </Box>
  )
}

const FiltersForm: FC<FiltersFormProps> = ({filters}) => {
  const {
    displayedFilters = {}, filterValues, hideFilter, setFilters, showFilter,
  } = useListContext()
  const styles = useStyles({hasFilters: true})
  const translate = useTranslate()
  const inactiveFilters = filters.filter(
    f => !(f.props.alwaysOn || displayedFilters.hasOwnProperty(f.props.source))
  )
  const getFieldLabel = useGetFieldLabel()
  const anchorElementRef = useRef<HTMLButtonElement>(null)
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  // We use an active filters set so that we can sort the rendered active filters
  // according to the order in which they were selected by the user.
  const [activeFilters, setActiveFilters] = useState(new Set<string>())
  useEffect(() => {
    // We run an effect to find a list of added and removed filters, which
    // can then be used to update the sorted list of active filters.
    // TODO: Run this block code outside of a `useEffect()` to reduce complexity.
    setActiveFilters(active => {
      difference(Object.keys(filterValues), Array.from(active.keys()))
        .forEach(f => active.add(f))
      difference(Array.from(active.keys()), Object.keys(filterValues))
        .forEach(f => active.delete(f))
      return active
    })
  }, [filterValues])
  return (
    <Box display="flex" flexDirection="row" justifyContent="start" width="100%">
      <EditContextProvider
        // @ts-ignore
        value={{save: values => setFilters({...filterValues, ...values}, null)}}
      >
        <SimpleForm className={styles.filterForm} toolbar={<></>}>
          {filters.filter(f => f.props.alwaysOn)}
          {Array.from(activeFilters)
            .reduce<FilterElement[]>((active, source) => {
              const filter = filters.find(f => f.props.source === source)
              return (!filter || filter.props.alwaysOn) ? active : [...active, filter]
            }, [])
            .map((f, i) => (
              // Used to clear a filled filter from the set of active filters.
              <FilledFilterChip
                childProps={f.props.children?.props}
                choices={f.props.choices}
                hideFilter={hideFilter}
                key={i}
                label={f.props.label ?? getFieldLabel(f.props.source)}
                optionText={f.props.optionText}
                optionValue={f.props.optionValue}
                reference={f.props.reference}
                source={f.props.source}
                value={filterValues[f.props.source]}
              />
            ))
          }
          {filters
            .filter(f => (
              displayedFilters.hasOwnProperty(f.props.source) &&
              !(f.props.alwaysOn || filterValues.hasOwnProperty(f.props.source))
            )).map((f, i) => (
              <div className={styles.filterInput} key={i}>
                {f}
                <CloseIcon onClick={() => hideFilter(f.props.source)}/>
              </div>
            ))
          }
          <Button
            className={styles.showInactiveFiltersButton}
            color="primary"
            disabled={!inactiveFilters.length}
            onClick={() => setIsPopperOpen(true)}
            ref={anchorElementRef}
            startIcon={<AddIcon />}
            variant="outlined"
          >
            {translate('actions.showInactiveFilters')}
          </Button>
          <EditFormAutoSave waitInterval={0} />
        </SimpleForm>
      </EditContextProvider>
      <Popper
        anchorEl={anchorElementRef.current}
        className={styles.addFilterPopper}
        open={isPopperOpen}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
          <Paper className={styles.addFilterPaper} elevation={5}>
            {inactiveFilters.map((filter, key) => (
              <Button
                className={styles.addFilterOption}
                fullWidth
                key={key}
                onClick={() => {
                  showFilter(filter.props.source, undefined)
                  setIsPopperOpen(false)
                }}
              >
                {filter.props.label ?? getFieldLabel(filter.props.source)}
              </Button>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}

const FilledFilterChip = ({
  childProps,
  choices,
  hideFilter,
  label,
  optionText,
  optionValue,
  reference,
  source,
  value,
}) => {
  const form = useForm()
  const filterLabel = reference ?
    <ReferenceFilterLabel
      {...{label, optionText: childProps.optionText, reference, value}}
    /> :
    <FilterLabel {...{choices, label, optionText, optionValue, value}}/>
  const styles = useStyles({hasFilters: true})
  return (
    <Chip
      className={styles.filterChip}
      deleteIcon={<CloseIcon />}
      label={filterLabel}
      onDelete={() => {
        hideFilter(source)
        form.change(source)
      }}
      variant="outlined"
    />
  )
}

const FilterLabel = ({choices, label, optionText, optionValue, value}) => {
  const {getChoiceText} = useChoices({optionText, optionValue})
  return (
    <>
      {label}: {' '}
      {optionText ? getChoiceText(choices?.find(c => c[optionValue] === value)) : value}
    </>
  )
}

const ReferenceFilterLabel = ({label, optionText, reference, value}) => {
  const {loaded, referenceRecord} = useReference({id: value, reference})
  const {getChoiceText} = useChoices({optionText})
  return loaded && referenceRecord ? <>{label}: {getChoiceText(referenceRecord)}</> : null
}

const ExtendedListHeader: FC<ExtendedListHeaderProps> = ({
  description, hasFilters, title, ...props
}) => {
  const styles = useStyles({hasFilters})
  return (
    <div className={styles.extendedListActions}>
      <DisplayBox desktop mobile={false}>
        <Box
          alignSelf="center"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          width="100%"
        >
          <ResourceTitle description={description} title={title} />
          <ExtendedListActions {...{...props, hasFilters}}/>
        </Box>
      </DisplayBox>
    </div>
  )
}

const ExtendedListActions: FC<ExtendedListActionsProps> = ({
  actionButtons, onCreateButtonClick,
}) => {
  const translate = useTranslate()
  const resource = useResourceContext()
  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" justifyContent="end">
      {actionButtons}
      {useHasPermission('create', resource) && (
        <CreateButton
          label={translate(`resources.${resource}.action.create`)}
          onClick={onCreateButtonClick}
          size="medium"
          variant="contained"
        />
      )}
    </Box>
  )
}

const useGetFieldLabel = () => {
  const translate = useTranslate()
  const resource = useResourceContext()
  return useCallback(
    (source: string) => translate(`resources.${resource}.fields.${source}`),
    [resource, translate],
  )
}

const useStyles = makeStyles<typeof Theme, MakeStylesOptions>(theme => ({
  addFilterOption: {
    justifyContent: 'start',
    margin: 0,
    padding: `${theme.remSpacing(1)} ${theme.remSpacing(2)}`,
  },
  addFilterPaper: {
    marginTop: theme.remSpacing(1),
    minWidth: theme.remSpacing(24),
  },
  addFilterPopper: {
    zIndex: 1000,
  },
  extendedListActions: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.remSpacing(2),
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  filterChip: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.remSpacing(1),
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.info.main,
    },
    borderColor: theme.palette.info.main,
    borderRadius: theme.remSpacing(1),
    color: theme.palette.info.main,
    height: theme.remSpacing(7.5),
  },
  filterForm: {
    '& .MuiCardContent-root': {
      [theme.breakpoints.down('md')]: {
        '& > div:first-child': {
          width: '100%',
        },
      },
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.remSpacing(2),
    },
    '& .MuiFilledInput-input': {
      width: theme.remSpacing(25),
    },
    flexGrow: 1,
  },
  filterInput: {
    '& > svg': {
      '&:hover': {
        backgroundColor: theme.palette.disabled.main,
      },
      background: theme.palette.background.paper,
      borderRadius: '50%',
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      padding: theme.remSpacing(0.5),
      position: 'absolute',
      right: theme.remSpacing(0.5),
      top: 'calc(50% - 24px)',
      transition: 'all .3s ease',
    },
    position: 'relative',
  },
  root: ({hasFilters}) => ({
    [
    '& .MuiToolbar-root:not(.MuiTablePagination-toolbar)' +
      ':not([data-test="bulk-actions-toolbar"])'
    ]: {
      flexDirection: 'column-reverse',
      marginBottom: theme.remSpacing(3),
      marginLeft: theme.remSpacing(5),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.remSpacing(4),
      },
    },
    marginTop: theme.remSpacing(4),
    ...(hasFilters && {
      '& .MuiTextField-root': {
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          padding: 0,
        },
        '& .MuiInputBase-input::placeholder': {
          color: theme.palette.text.secondary,
          opacity: 1,
        },
      },
      '& form': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        '& .filter-field': {
          [theme.breakpoints.down('sm')]: {
            // Select the element that adds an empty space to the right
            '& > :nth-of-type(2)': {
              display: 'none',
            },
            alignSelf: 'center',
            margin: 0,
            padding: 0,
            width: '100%',
          },
        },
        width: '100%',
      },
    }),
  }),
  selectAllPagesField: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.remSpacing(3),
    },
    marginLeft: theme.remSpacing(2),
  },
  showInactiveFiltersButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.remSpacing(1),
    },
    height: theme.remSpacing(7.5),
    margin: 0,
  },
}))

interface ExtendedListProps extends ListProps {
  actionButtons?: ReactNode
  canSelectAllPages?: boolean,
  children: ReactElement,
  description?: ReactNode,
  filters?: FiltersFormProps['filters'],
  onCreateButtonClick?: MouseEventHandler<HTMLButtonElement>
}

interface MakeStylesOptions {
  hasFilters?: boolean,
}

interface ExtendedListHeaderProps extends
  Pick<ExtendedListProps, 'actionButtons'|'description'|'onCreateButtonClick'|'title'>{
  hasFilters: boolean
}

interface FiltersFormProps {
  filters: [FilterElement, ...FilterElement[]]
}

type FilterElement = ReactElement<{
  [key:string]: any
  alwaysOn?: boolean
  label: string
  source: string
}>

type ExtendedListActionsProps =
  Pick<ExtendedListProps, 'actionButtons'|'onCreateButtonClick'>

export default ExtendedList
