import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {uniq} from 'lodash'
import {FC, ReactNode, SyntheticEvent, useCallback, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedDialog from '../../components/ExtendedDialog'
import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import {Chats, MutationRoot, QueryRoot, Users} from '../../types/graphqlSchema'

const ChatEditDialog: FC<ChatNameEditDialogProps> = ({
  chat,
  children,
  initialValue,
  onClose,
  open = false,
}) => {
  const styles = useStyles()
  const nameInputRef = useRef<HTMLInputElement>()
  const [searchText, setSearchText] = useState('')
  const userDisplayName = useUserDisplayName()
  const [updateChatName] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION, {variables: {id: chat.id}}
  )
  const [addChatUsers] = useMutation<MutationRoot['insert_chat_users']>(
    INSERT_CHAT_USERS,
  )
  const [deleteChatUsers] = useMutation<MutationRoot['delete_chat_users']>(
    DELETE_CHAT_USERS
  )
  const {
    data: {merchant_users: merchantUsers = []} = {},
  } = useQuery<QueryRoot['merchant_users']>(MERCHANT_USERS_QUERY, {skip: !open})
  const internalChatUsers: Users[] = chat.chatUsers.map(chatUser => chatUser.user)
  const addedUserIds = useRef<string[]>([])
  const removedUserIds = useRef<string[]>([])
  const isChatMember = useCallback(
    (userId: string) => internalChatUsers.some(u => u.id === userId),
    [internalChatUsers],
  )
  const close = useCallback((e: SyntheticEvent) => {
    addedUserIds.current = []
    removedUserIds.current = []
    onClose(e)
  }, [onClose])
  return (
    <ExtendedDialog
      aria-labelledby="form-dialog-title"
      onClick={e => e.stopPropagation()}
      onClose={close}
      open={open}
      scroll="paper"
    >
      <DialogTitle>
        {useTranslate()('dialogs.chatEdit.title')}
      </DialogTitle>
      <DialogCloseButton onClick={close} />
      <DialogContent>
        {children}
        <Typography paragraph variant="subtitle1">
          {useTranslate()('dialogs.chatEdit.editChatName')}
        </Typography>
        <TextField
          autoFocus
          defaultValue={initialValue}
          inputRef={nameInputRef}
          placeholder={useTranslate()('dialogs.chatEdit.renameInputPlaceholder')}
          variant="filled"
        />
        <Box className={styles.merchantUsersContainer}>
          <Typography paragraph variant="subtitle1">
            {useTranslate()('dialogs.chatEdit.manageMembersTitle')}
          </Typography>
          <TextField
            onChange={e => setSearchText(e.target.value)}
            placeholder={
              useTranslate()('dialogs.chatEdit.searchUsersInputPlaceholder')
            }
            value={searchText}
            variant="filled"
          />
          <br/><br/>
          <>
            {merchantUsers.map(mu => (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={isChatMember(mu.user?.id as string)}
                  />
                }
                key={mu.id}
                label={userDisplayName({merchantUser: mu})}
                onChange={(_, checked) => {
                  if (checked) {
                    addedUserIds.current = uniq([
                      ...addedUserIds.current, mu.user?.id as string,
                    ])
                    // Make sure the member in the "added list" is not in the
                    // "removed list"
                    removedUserIds.current = removedUserIds.current.filter(
                      id => id !== mu.user?.id
                    )
                  }
                  else {
                    removedUserIds.current = uniq([
                      ...removedUserIds.current, mu.user?.id as string,
                    ])
                    // Make sure the member in the "removed list" is not present in the
                    // "added list"
                    addedUserIds.current = addedUserIds.current.filter(
                      id => id !== mu.user?.id
                    )
                  }
                }}
                style={{
                  display: userDisplayName(
                    {merchantUser: mu})?.toLowerCase().includes(
                    searchText.trim().toLowerCase()
                  ) ? 'inherit' : 'none',
                }}
              />
            ))}
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={e => {
            updateChatName({variables: {name: nameInputRef.current?.value}})
            addChatUsers({
              variables: {
                objects: addedUserIds.current.map(userId => ({chatId: chat.id, userId})),
              },
            })
            deleteChatUsers({
              variables: {
                chatId: chat.id,
                userIds: removedUserIds.current,
              },
            })
            close(e)
          }}
          variant="contained"
        >
          {useTranslate()('actions.confirm')}
        </Button>
      </DialogActions>
    </ExtendedDialog>
  )
}

const MERCHANT_USERS_QUERY = gql`query{
  merchant_users{firstName id lastName user{id}}
}`
const UPDATE_CHAT_MUTATION = gql`
  mutation($id: uuid!, $name: String) {
    update_chats_by_pk(
      pk_columns: {id: $id}, _set: {name: $name}
    ){id name}
  }
`
const INSERT_CHAT_USERS = gql`
  mutation($objects: [chat_users_insert_input!]!){
    insert_chat_users(
      objects: $objects
      on_conflict: {constraint: chat_users_pkey, update_columns: []}
    ){returning{chatId userId}}
  }
`
const DELETE_CHAT_USERS = gql`
  mutation($chatId: uuid!, $userIds: [uuid!]!){
    delete_chat_users(
      where: {chatId: {_eq: $chatId}, userId: {_in: $userIds}}
    ){affected_rows}
  }
`

const useStyles = makeStyles(theme => ({
  merchantUsersContainer: {
    marginTop: theme.remSpacing(3),
  },
}))

interface ChatNameEditDialogProps {
  chat: Chats
  children?: ReactNode
  initialValue?: string
  onClose: (e: SyntheticEvent | Record<string, any>) => void
  open?: boolean
}

export default ChatEditDialog
