import {useLazyQuery} from '@apollo/react-hooks'
import {Box, Popper, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import _ from 'lodash'
import {FC, useEffect, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import useHasFocus from '../../../hooks/useHasFocus'
import {QueryRoot, QueryRootTranslateTextArgs} from '../../../types/graphqlSchema'
import {TranslationIcon} from '../../icons'

const TranslationPopper: FC<TranslationPopperProps> = ({
  inputElement,
  inputText,
  targetLanguage,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const hasInputFocus = useHasFocus(inputElement)
  const isOpen = !!(targetLanguage && inputText && hasInputFocus)
  const [translatedText, setTranslatedText] = useState('')
  const [
    translateText, {loading: isLoading},
  ] = useLazyQuery<QueryRoot['translateText'], QueryRootTranslateTextArgs>(
    TRANSLATE_TEXT_QUERY,
    {
      onCompleted: data => {
        setTranslatedText(data?.translateText?.text ?? '')
      },
    }
  )
  const debounceTranslateText = useRef<any>()
  // TODO: Create a custom and reusable [useDebounce] hook.
  useEffect(() => {
    // Cancel the previous debounce function to prevent multiple parallel calls to the
    // translation api.
    // This makes at the end the translation api to be called only when the user stops
    // typing.
    debounceTranslateText.current?.cancel()
    debounceTranslateText.current = _.debounce(
      () => {
        translateText({variables: {targetLanguage, text: inputText.trim()}})
      },
      1000,
    )
    debounceTranslateText.current()
  }, [inputText, translateText, targetLanguage])
  useEffect(() => {
    if (!isOpen) debounceTranslateText.current?.cancel()
  }, [isOpen, debounceTranslateText])
  // TODO: Updates for material ui v5 (!BREAKING CHANGES!).
  //  - use [popperOptions] instead of [modifiers]
  //  - the [placement] attribute is used within [popperOptions] and thus will be removed
  //    from the prop list
  return (
    <Popper
      anchorEl={inputElement}
      className={styles.popper}
      modifiers={{offset: {offset: '0, 35'}}}
      open={isOpen}
      placement="top-start"
    >
      <Box className={styles.arrow} />
      <Typography>
        <div className={styles.languageContentWrapper}>
          <TranslationIcon />
          <span className={styles.languageContent}>
            {translate(
              'chat.toolbar.translationPopper.translatedInto',
              {
                language: translate(`languages.${targetLanguage}`),
                loadingDots: isLoading ? '...' : '',
              }
            )}
          </span>
        </div>
        <span className={styles.translatedText}>{translatedText}</span>
      </Typography>
    </Popper>
  )
}

const TRANSLATE_TEXT_QUERY = gql`
  query($text: String!, $targetLanguage: String!) {
    translateText(text: $text, targetLanguage: $targetLanguage) {text}
  }
`

const useStyles = makeStyles(theme => ({
  arrow: {
    '&::before': {
      backgroundColor: 'white',
      bottom: '-8px',
      content: '""',
      display: 'block',
      height: '20px',
      left: '5%',
      position: 'absolute',
      transform: 'rotate(45deg)',
      width: '20px',
    },
  },
  languageContent: {
    marginLeft: theme.remSpacing(0.5),
  },
  languageContentWrapper: {
    display: 'flex',
    justifyItems: 'center',
  },
  popper: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '280px',
    },
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '3px 3px 10px 2px #ccc',
    padding: '20px',
    zIndex: 1002,
  },
  translatedText: {
    whiteSpace: 'pre-line',
  },
}))

interface TranslationPopperProps {
  hasInputFocus?: boolean,
  inputElement?: HTMLInputElement,
  inputText: string,
  targetLanguage: string
}

export default TranslationPopper
