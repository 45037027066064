import {ApolloClient} from 'apollo-client'
import {ApolloLink, Observable, split} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {HttpLink} from 'apollo-link-http'
import {RetryLink} from 'apollo-link-retry'
import {WebSocketLink} from 'apollo-link-ws'
import {getMainDefinition} from 'apollo-utilities'
import {OperationDefinitionNode} from 'graphql/language/ast'
import buildHasuraProvider from 'ra-data-hasura'

const buildDataProvider = async ({cache, getToken, uri, websocketUri}, {buildFields}) => {
  const setAuthorizationHeader = operation => getToken().then(token => {
    if (!token) return
    operation.setContext({headers: {authorization: `Bearer ${token}`}})
  })
  // TODO: The app gets stuck in a loading state when there's no token in local storage
  //      since getToken() throws an error. For now, we catch this error and do nothing.
    .catch(() => {})
  // TODO: Is there a way to express this link simpler/shorter?
  const authLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let unsubscribe
      Promise.resolve(operation)
        .then(setAuthorizationHeader)
        .then(() => {
          const subscription = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer),
          })
          unsubscribe = subscription.unsubscribe.bind(subscription)
        })
        .catch(observer.error.bind(observer))
      return () => {unsubscribe?.()}
    })
  )
  const httpLink = new HttpLink({uri})
  const wsLink = new WebSocketLink({
    options: {
      connectionParams: () => getToken().then(token =>
        token ? {headers: {Authorization: `Bearer ${token}`}} : {}
      ),
      // `lazy` is required according to:
      // https://github.com/apollographql/apollo-client/issues/3967#issuecomment-450255702
      lazy: true,
      reconnect: true,
    },
    uri: websocketUri,
  })
  const requestLink = split(
    ({query}) => {
      const {kind, operation} = getMainDefinition(query) as OperationDefinitionNode
      return (kind === 'OperationDefinition') && (operation === 'subscription')
    },
    wsLink,
    httpLink,
  )
  // Documentation on retryLink:
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  const retryLink = new RetryLink({
    attempts: {
      // retries at most 5 times
      max: 5,
      // a retry will happen for any error.
      retryIf: error => !!error,
    },
    delay: {
      // minimum delay of 300ms
      initial: 300,
      // delays between request retries will be randomized
      jitter: true,
      // maximum delay of 5000ms
      max: 5000,
    },
  })
  _apolloClient = new ApolloClient({
    cache,
    defaultOptions: {
      // Note that react-admin overrides the `fetchPolicy` for each query which gets
      // generated by the react-admin graphql data provider. See also:
      // https://github.com/marmelab/react-admin/blob/master/packages/ra-data-graphql/README.md#troubleshooting
      // @ts-ignore
      mutation: {errorPolicy: 'all'},
      query: {errorPolicy: 'all'},
      watchQuery: {errorPolicy: 'all'},
    },
    link: ApolloLink.from([
      retryLink,
      // TODO: extract error link into `errorLink` variable for easier readability
      onError(({
        graphQLErrors, networkError, operation: {getContext, query, variables},
      }) => {
        const context = getContext()
        const queryDocument = query.loc?.source.body
        graphQLErrors?.forEach(e => {
          // Ignore missing query names where there's no auth header
          // TODO: Avoid making automatic queries/subscription in the app when the
          //       user isn't authenticated yet e.g. for channels on sidebar etc.
          if (
            context.headers?.authorization ||
            !(/^field '([a-zA-Z]|_)+' not found in type: '([a-zA-Z]|_)+'$/)
              .test(e.message)
          ) {
            logError(e, "GraphQL", queryDocument, variables)
          }
        })
        // TODO: errors should be intercepted by authProvider.checkError()
        //       We want the user session to finish if network repsonse is 401
        if (networkError) {
          logError(networkError, "Network", queryDocument, variables)
        }
      }),
      // Adding authentication information should happen on every retry attempt as the
      // access token might change between retries
      authLink,
      // TODO: Add a timeout link to cancel too long running requests, see also:
      //       https://medium.com/@joanvila/productionizing-apollo-links-4cdc11d278eb#726e
      requestLink,
    ]),
  })
  const dataProvider = await buildHasuraProvider({client: _apolloClient}, {buildFields})
  // Modify the data provider to append client-side params
  // This is useful when the table we're dealing with doesn't have the id column,
  // but a react-admin resource depends on the presence of such a column.
  // NOTE: When updating ra-data-hasura, update customizedDataProvider signature as well.
  const customizedDataProvider = async (fetchType, resourceType, params) => {
    if (
      (fetchType === 'GET_LIST') &&
      (resourceType === 'aggregated_merchant_customer_user_tags')
    ) {
      const {data, ...metadata} = await dataProvider(fetchType, resourceType, params)
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.merchantCustomerUserId}|${row.tagType}|${row.tagName}`,
        })),
      }
    }
    if (
      (fetchType === 'GET_MANY') &&
      (resourceType === 'merchant_customer_user_tags')
    ) {
      const {data, ...metadata} = await dataProvider('GET_LIST', resourceType, {
        'merchantCustomerUserId@_in': params.ids,
      })
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.merchantCustomerUserId}|${row.tagId}`,
        })),
      }
    }
    if (
      (fetchType === 'CREATE') &&
      (resourceType === 'merchant_customer_user_tags')
    ) {
      const {
        data: {merchantCustomerUserId, tagId},
      } = await dataProvider(fetchType, resourceType, params)
      return {
        data: {id: `${merchantCustomerUserId}|${tagId}`, merchantCustomerUserId, tagId},
      }
    }
    if (
      (fetchType === 'GET_LIST') &&
      (resourceType === 'merchant_customer_user_contact_groups')
    ) {
      const {data, ...metadata} = await dataProvider(fetchType, resourceType, params)
      return {
        ...metadata,
        data: data.map(
          row => ({...row, id: `${row.contactGroupId}|${row.merchantCustomerUserId}`})
        ),
      }
    }
    if (
      (fetchType === 'CREATE') &&
      (resourceType === 'merchant_customer_user_contact_groups')
    ) {
      const {
        data: {contactGroupId, merchantCustomerUserId},
      } = await dataProvider(fetchType, resourceType, params)
      return {
        data: {
          contactGroupId,
          id: `${merchantCustomerUserId}|${contactGroupId}`,
          merchantCustomerUserId,
        },
      }
    }
    if (
      (fetchType === 'GET_LIST') &&
      (resourceType === 'merchant_user_contact_groups')
    ) {
      const {
        data, ...metadata
      } = await dataProvider(fetchType, resourceType, params)
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.merchantCustomerUserId}|${row.contactGroupId}`,
        })),
      }
    }
    if (
      (fetchType === 'GET_MANY') &&
      (resourceType === 'merchant_customer_user_contact_groups')
    ) {
      const {data, ...metadata} = await dataProvider('GET_LIST', resourceType, {
        'merchantCustomerUserId@_in': params.ids,
      })
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.merchantCustomerUserId}|${row.contactGroupId}`,
        })),
      }
    }
    if (
      (fetchType === 'GET_MANY_REFERENCE') &&
      (resourceType === 'merchant_user_contact_groups')
    ) {
      const {
        data, ...metadata
      } = await dataProvider(
        fetchType,
        resourceType,
        {...params, sort: {field: 'contactGroupId', order: 'ASC'}},
      )
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.merchantCustomerUserId}|${row.contactGroupId}`,
        })),
      }
    }
    if (
      (fetchType === 'CREATE') &&
      (resourceType === 'merchant_user_contact_groups')
    ) {
      const {
        data: {contactGroupId, merchantCustomerUserId},
      } = await dataProvider(fetchType, resourceType, params)
      return {
        data: {
          contactGroupId,
          id: `${merchantCustomerUserId}|${contactGroupId}`,
          merchantCustomerUserId,
        },
      }
    }
    if (
      (fetchType === 'GET_LIST') &&
      (resourceType === 'merchant_customer_user_tags')
    ) {
      const {data, ...metadata} = await dataProvider(fetchType, resourceType, params)
      return {
        ...metadata,
        data: data.map(row => ({...row, id: row.tagId})),
      }
    }
    if (
      (fetchType === 'GET_MANY_REFERENCE') &&
      (resourceType === 'channel_merchant_users')
    ) {
      const {data, ...metadata} = await dataProvider(fetchType, resourceType, params)
      return {
        ...metadata,
        data: data.map(row => ({
          ...row, id: `${row.channelId}|${row.merchantUserId}`,
        })),
      }
    }
    return await dataProvider(fetchType, resourceType, params)
  }
  return customizedDataProvider
}

const logError = (error, errorType, queryDocument, variables) => {
  console.error(
    `${errorType} error: ${JSON.stringify({error, queryDocument, variables})}`
  )
}

let _apolloClient

export default buildDataProvider
// TODO: Stop exposing this once we use the react-admin realtime feature for subscriptions
export {_apolloClient}
